<template>
  <v-card class="card-shadow border-radius-xl">
    <common-confirm
      :title="confirm.title"
      :message="confirm.message"
      :confirm_dialog="confirm.dialog"
      :confirm_function="confirm.function"
      v-on:confirm="confirm.dialog = false"
      v-on:cancel="confirm.dialog = false"
    ></common-confirm>
    <!-- progress -->
    <common-loading
      :title="loading.title"
      :message="loading.message"
      :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading"
      :show_confirm="loading.show_confirm"
      v-on:confirm="loading.dialog = false"
    >
    </common-loading>
    <!-- review dialog -->
    <v-card-text class="px-0 py-0">
      <v-simple-table class="table border-radius-xl">
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="auto">
                <v-btn
                  :ripple="false"
                  elevation="0"
                  color="#fff"
                  class="
                    font-weight-bolder
                    btn-default
                    bg-gradient-default
                    py-5
                    px-6
                    mx-3
                    ms-auto
                  "
                  small
                  @click="$emit('is_active', true)"
                >
                  <v-icon class="me-2" size="12">mdi-account-outline</v-icon>
                  有效
                </v-btn>
                <v-btn
                  :ripple="false"
                  elevation="0"
                  color="#fff"
                  class="
                    font-weight-bolder
                    btn-default btn-outline-secondary
                    py-5
                    px-6
                    mx-3
                    ms-auto
                  "
                  small
                  @click="$emit('is_active', false)"
                >
                  <v-icon class="me-2">mdi-account-off-outline</v-icon>
                  失效
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn
                  :ripple="false"
                  elevation="0"
                  color="#fff"
                  class="
                    font-weight-bolder
                    btn-default
                    bg-gradient-primary
                    py-5
                    px-6
                  "
                  small
                  @click="$router.push({ name: 'AddUser' })"
                >
                  <v-icon class="me-2" size="12">fa fa-user-plus</v-icon>
                  {{ $t("New User") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:default>
          <thead>
            <tr>
              <td
                class="text-secondary font-weight-bolder text-md border-bottom"
              >
                帳號
              </td>
              <td
                class="text-secondary font-weight-bolder text-md border-bottom"
              >
                姓名
              </td>
              <td
                class="text-secondary font-weight-bolder text-md border-bottom"
              >
                角色
              </td>

              <td
                class="text-secondary font-weight-bolder text-md border-bottom"
              >
                Email
              </td>
              <td
                class="text-secondary font-weight-bolder text-md border-bottom"
              >
                手機
              </td>
              <td
                class="text-secondary font-weight-bolder text-md border-bottom"
              >
                最後登入
              </td>
              <td
                class="text-secondary font-weight-bolder text-md border-bottom"
              ></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in users" :key="item.id">
              <td :class="{ 'border-bottom': i != users.length - 1 }">
                <v-list class="py-0">
                  <v-list-item class="px-1 py-1">
                    <!-- <v-list-item-avatar rounded :size="36" class="my-0 me-5">
                      <v-img
                        :alt="avatar[0]"
                        :src="avatar[0]"
                        class="border-radius-lg"
                      ></v-img>
                    </v-list-item-avatar> -->

                    <v-list-item-content>
                      <v-list-item-title class="text-md font-weight-bold">{{
                        item.username
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </td>
              <td :class="{ 'border-bottom': i != users.length - 1 }">
                <span class="font-weight-bold mb-0 text-md"
                  >{{ item.last_name }}{{ item.first_name }}</span
                >
              </td>

              <td :class="{ 'border-bottom': i != users.length - 1 }">
                <p class="font-weight-bold mb-0 text-md">
                  {{ item.role | getText(getRoles()) }}
                </p>
              </td>
              <td :class="{ 'border-bottom': i != users.length - 1 }">
                <p class="font-weight-bold mb-0 text-md">{{ item.email }}</p>
              </td>
              <td :class="{ 'border-bottom': i != users.length - 1 }">
                <p class="font-weight-bold mb-0 text-md">
                  {{ item.mobile_phone }}
                </p>
              </td>
              <td :class="{ 'border-bottom': i != users.length - 1 }">
                <span class="font-weight-bold text-sm">{{
                  item.last_login | dateFormatDashTimezone
                }}</span>
              </td>
              <td
                :class="{ 'border-bottom': i != users.length - 1 }"
                class="text-end"
              >
                <!-- actions -->
                <v-btn
                  v-if="!item.is_superuser"
                  :ripple="false"
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-danger
                    bg-gradient-danger
                    ma-2
                    px-5
                  "
                  @click="showResetDialog(item)"
                >
                  <!-- <v-icon class="me-2" size="12">fa fa-times</v-icon> -->
                  重設密碼</v-btn
                >
                <v-btn
                  :ripple="false"
                  elevation="0"
                  color="#fff"
                  class="
                    font-weight-bold
                    text-xs
                    btn-danger
                    bg-gradient-default
                    ma-2
                    px-5
                  "
                  @click="editUser(item)"
                >
                  <!-- <v-icon class="me-2" size="12">fa fa-user-plus</v-icon> -->
                  編輯帳號
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import { role_enum_str, edit_mode_enum } from "@/definitions.js";
import CommonUtility from "@/util/CommonUtility.js";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import { mapGetters, mapState } from "vuex";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

export default {
  name: "users-table",
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reset_msg: null,
      dialog: false,
      avatar: [require("@/assets/img/team-1.jpg")],
      role_enum_str: role_enum_str,
      loading: {
        dialog: false,
        title: null,
        message: null,
        show_loading: false,
        show_confirm: true,
      },
      confirm: {
        dialog: false,
        title: null,
        message: null,
        function: null,
      },
    };
  },
  components: {
    CommonConfirm,
    CommonLoading,
  },
  computed: {
    ...mapState(["roles"]),
  },
  mixins: [HttpCommonMixin],
  mounted(){
  },
  methods: {
    ...mapGetters(["getToken","getRoles"]),
    
    send_verify(item) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/${item.id}/verify`;

      this.doHttpPost(url, {}, () => {
        this.showSuccessAlert(`Send verify mail to ${item.username}`);
      });
    },
    showSuccessAlert(message) {
      // this.$swal("User", message, "success");
      this.loading.title = "資訊";
      this.loading.message = message;
      this.loading.dialog = true;
    },
    updateUser(item) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/${item.id}`;
      this.doHttpPut(url, item, () => {
        this.showSuccessAlert(`Update user ${item.username} success`);
      });
    },
    showResetDialog(item) {
      this.editItem = item;
      this.confirm.title = "重設密碼";
      this.confirm.message = "重設密碼" + item.username + "的密碼";
      this.confirm.function = this.resetPassword;
      this.confirm.dialog = true;
    },
    resetPassword() {
      this.confirm.dialog = false;
      let item = this.editItem;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/${item.id}/reset_password`;
      this.doHttpPut(
        url,
        {},
        () => {
          this.showSuccessAlert(`重設密碼 ${item.username} 成功`);
        },
        null,
        this.close()
      );
    },
    close() {
      this.reset_msg = null;
      this.editItem = null;
      this.dialog = false;
    },
    editUser(item) {
      this.$router.push({
        name: "EditUser",
        params: { data: JSON.stringify(item), edit_mode: edit_mode_enum.Edit },
      });
    },
  },
};
</script>
